import { twMerge } from 'tailwind-merge';
import { type ClassValue, clsx } from 'clsx';
import { redirect } from 'react-router-dom';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

interface FetchArgs<T> {
  auth?: boolean;
  url: string;
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  body?: T;
}

export async function f<T>({ auth = false, url, body, method }: FetchArgs<T>) {
  const getAuthToken = localStorage.getItem('vf-auth-token');

  const response = await fetch(
    `${import.meta.env.VITE_API_BASE_URL}/api${url}`,
    {
      method,
      headers: {
        'Content-Type': 'application/json',
        ...(auth && getAuthToken
          ? {
              authorization: `Bearer ${getAuthToken}`,
            }
          : {}),
      },
      // Adding credential property to compatibility with v2 APIs
      credentials: 'include',
      ...(body
        ? {
            body: JSON.stringify(body),
          }
        : {}),
    },
  );

  // Return 'ok' for status code 202 (Accepted)
  if (response.status === 202) {
    return 'accepted';
  }

  // Return 'same' for status code 304 (Not Modified)
  if (response.status === 304) {
    return 'notModified';
  }

  const data = await response.json();

  if (data?.code === 'USER_NOT_FOUND' || data?.code === 'INVALID_TOKEN') {
    localStorage.removeItem('vf-auth-token');
    localStorage.removeItem('vf-user');
    redirect('/login');
  }

  return data;
}

export async function fV2<T>({ url, body, method }: FetchArgs<T>) {
  const response = await fetch(
    `${import.meta.env.VITE_API_V2_BASE_URL}/api/v2${url}`,
    {
      method,
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      ...(body
        ? {
            body: JSON.stringify(body),
          }
        : {}),
    },
  );

  // Return 'ok' for status code 202 (Accepted)
  if (response.status === 202) {
    return 'accepted';
  }

  return await response.json();
}
