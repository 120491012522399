import {
  createBrowserRouter,
  redirect,
  type LoaderFunctionArgs,
} from 'react-router-dom';
import React from 'react';

import { getSite, getSites } from './models/sites';
import { getPosts } from './models/posts';
import { getCategories } from './models/category';
import { getAuthUserInfo } from './models/auth';

async function isAuthedLoader(args: LoaderFunctionArgs<unknown>) {
  const url = new URL(args.request.url);

  if (typeof window === 'undefined') return null;

  const data = await getAuthUserInfo();
  if ('id' in data) {
    // Redirect to homepage, if already logged-in
    if (url.pathname === '/login' || url.pathname === '/signup') {
      return redirect('/');
    }
    return null;
  }

  // TODO: Create logout page
  if (!(url.pathname === '/login' || url.pathname === '/signup')) {
    const logoutUrl = new URL(`${url.origin}/login`);
    const searchParams = new URLSearchParams();
    searchParams.set('redirectTo', url.pathname);

    if ('code' in data) {
      return redirect(`${logoutUrl}?${searchParams.toString()}`);
    }
  }

  // TODO: After logout redirect to login page

  return null;
}

export const router = createBrowserRouter([
  {
    path: '/',
    loader: isAuthedLoader,
    lazy: async () => await import('./app/layout'),
    id: 'TeamsLayout',
    children: [
      {
        path: '',
        lazy: async () => await import('./app/page'),
        id: 'TeamsPage',
      },
      {
        path: '/account/settings',
        // id: "AccountLayout",
        lazy: async () => await import('./app/account/settings/page'),
        id: 'AccountSettingsPage',
      },
    ],
  },
  {
    path: 'signup',
    loader: isAuthedLoader,
    lazy: async () => await import('./app/signup'),
    id: 'SignupPage',
  },
  {
    path: 'login',
    loader: isAuthedLoader,
    lazy: async () => await import('./app/login'),
    id: 'LoginPage',
  },
  {
    path: 'logout',
    lazy: async () => await import('./app/logout'),
    id: 'LogoutPage',
  },

  // TeamId > Sites
  {
    path: '/:teamId',
    loader: isAuthedLoader,
    lazy: async () => await import('./app/[teamId]/layout'),
    id: 'TeamLayout',
    children: [
      {
        path: 'sites',
        loader: async ({ params }) => {
          const teamId = params.teamId;
          if (!teamId) return [];

          const response = await getSites({ teamId });
          if (response) {
            return response;
          }

          return null;
        },
        lazy: async () => await import('./app/[teamId]/sites/page'),
        id: 'SitesPage',
      },
      {
        path: 'billing',
        lazy: async () => await import('./app/[teamId]/billing/page'),
        id: 'TeamBillingPage',
      },
      {
        path: 'import',
        lazy: async () => await import('./app/[teamId]/import/page'),
        id: 'TeamImportPage',
      },
      {
        path: 'settings',
        lazy: async () => await import('./app/[teamId]/settings/page'),
        id: 'TeamSettingsPage',
      },
    ],
  },

  // Sites > Site
  {
    path: '/:teamId/sites/:siteId',
    lazy: async () => await import('./app/[teamId]/sites/[siteId]/layout'),
    id: 'SiteLayout',
    errorElement: <>Site not found</>,
    loader: async ({ params, request }) => {
      if (typeof window === 'undefined') return null;

      const data = await getAuthUserInfo();
      const url = new URL(request.url);
      if (url.pathname !== '/login') {
        if ('code' in data) {
          return redirect('/logout');
        }
      }

      if (params.siteId) {
        const siteData = await getSite({ siteId: params.siteId });

        return {
          site: siteData.site,
        };
      }
      return 'SITE_NOT_FOUND';
    },
    children: [
      {
        path: 'posts',
        errorElement: <>Site not found</>,
        loader: async ({ params }) => {
          if (params.siteId) {
            const siteData = await getSite({ siteId: params.siteId });

            if (siteData.site) {
              const listPosts = await getPosts({
                siteId: siteData.site.id,
              });

              return {
                site: siteData.site,
                posts: listPosts.posts,
              };
            }
            return 'SITE_NOT_FOUND';
          }
          return 'SITE_NOT_FOUND';
        },
        lazy: async () =>
          await import('./app/[teamId]/sites/[siteId]/posts/page'),
        id: 'SitePostsPage',
      },
      {
        path: 'posts/:postId',
        loader: async ({ params }) => {
          if (params.siteId) {
            const siteData = await getSite({ siteId: params.siteId });

            if (siteData.site) {
              return {
                site: siteData.site,
              };
            }
            return 'SITE_NOT_FOUND';
          }
          return 'SITE_NOT_FOUND';
        },
        lazy: async () =>
          await import('./app/[teamId]/sites/[siteId]/posts/[postId]/page'),
        id: 'SitePostEditPage',
      },
      {
        path: 'category',
        errorElement: <>Site not found</>,
        loader: async ({ params }) => {
          if (params.siteId) {
            const siteData = await getSite({ siteId: params.siteId });

            if (siteData.site) {
              const listCategories = await getCategories({
                siteId: siteData.site.id,
              });

              return {
                site: siteData.site,
                categories: listCategories.categories,
              };
            }

            return 'SITE_NOT_FOUND';
          }

          return 'SITE_NOT_FOUND';
        },
        lazy: async () =>
          await import('./app/[teamId]/sites/[siteId]/category/page'),
        id: 'SiteCategoryPage',
      },
      {
        path: 'category/:categoryId',
        lazy: async () =>
          await import(
            './app/[teamId]/sites/[siteId]/category/[categoryId]/page'
          ),
        id: 'SiteCategoryEditPage',
      },
      {
        path: 'settings',
        lazy: async () =>
          await import('./app/[teamId]/sites/[siteId]/settings/layout'),
        id: 'SiteSettingsLayout',
        children: [
          {
            path: 'webhook',
            lazy: async () =>
              await import(
                './app/[teamId]/sites/[siteId]/settings/webhooks/page'
              ),
            id: 'SiteWebhooksPage',
          },
          {
            path: 'webhook/:webhookId',
            lazy: async () =>
              await import(
                './app/[teamId]/sites/[siteId]/settings/webhooks/[webhookId]/page'
              ),
            id: 'SiteWebhookEditPage',
          },
        ],
      },
      {
        path: 'tokens',
        lazy: async () =>
          await import('./app/[teamId]/sites/[siteId]/tokens/page'),
        id: 'SiteTokensPage',
      },
    ],
  },
]);
