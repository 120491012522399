import { redirect } from 'react-router-dom';
import type {
  SiteObject,
  RetrieveSiteParameters,
  RetrieveSiteResponse,
} from '@voidfull/js-sdk';

import { f } from '../utils';

export interface GetSitesArgs {
  teamId: string;
}

export async function getSites({
  teamId,
}: GetSitesArgs): Promise<SiteObject[]> {
  const searchParams = new URLSearchParams();
  if (teamId) {
    searchParams.set('teamId', teamId);
  }

  const data = await f({
    auth: true,
    url: `/v1/sites?${searchParams.toString()}`,
    method: 'GET',
  });

  if (data?.code === 'TEAM_ID_REQUIRED') {
    redirect('/');
  }

  if ('sites' in data) {
    return data.sites;
  }

  throw data;
}

export async function getSite({
  siteId,
}: Required<RetrieveSiteParameters>): Promise<RetrieveSiteResponse> {
  const data = await f({
    auth: true,
    url: `/v1/sites/${siteId}`,
    method: 'GET',
  });

  return {
    site: data.site,
  };
}
