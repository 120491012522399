import type {
  CategoryStatus,
  ListCategoryParameters,
  PageInfo,
} from '@voidfull/js-sdk';

import { f } from '../utils';

export interface Category {
  id: string;
  name: string;
  slug: string;
  description: string;
  icon: string;
  parentId?: string;
  status: CategoryStatus;
  siteId: string;
  createdAt: string;
  updatedAt?: string;
}

interface ListCategoryArgs extends Omit<ListCategoryParameters, 'siteId'> {
  siteId: string;
}

export interface ListCategoryResponse {
  categories: Category[];
  pageInfo: PageInfo;
}

export async function getCategories({
  siteId,
  status,
  exclude,
  include,
}: ListCategoryArgs): Promise<ListCategoryResponse> {
  const pathname = `/v1/sites/${siteId}/category`;

  const searchParams = new URLSearchParams();
  if (typeof status !== 'undefined') {
    const statusArray = Array.isArray(status) ? status : [status];
    statusArray.map((item) => {
      searchParams.append('status', item);
    });
  }
  if (typeof exclude !== 'undefined') {
    const excludeArray = Array.isArray(exclude) ? exclude : [exclude];
    excludeArray.map((item) => {
      searchParams.append('exclude', item);
    });
  }
  if (typeof include !== 'undefined') {
    const includeArray = Array.isArray(include) ? include : [include];
    includeArray.map((item) => {
      searchParams.append('include', item);
    });
  }

  const url = `${pathname}${
    searchParams.size > 0 ? `?${searchParams.toString()}` : ''
  }`;

  const data = await f({
    url,
    auth: true,
    method: 'GET',
  });
  if ('categories' in data) {
    return {
      categories: data.categories,
      pageInfo: data.pageInfo,
    };
  }

  throw data;
}

export interface CreateCategoryArgs {
  readonly siteId: string;
  name: string;
  readonly parentId?: string;
  status?: CategoryStatus;
}

interface CreateCategoryResponse {
  category: Category;
}

export async function createCategory({
  siteId,
  name,
  parentId,
}: CreateCategoryArgs): Promise<CreateCategoryResponse> {
  const data = await f({
    auth: true,
    url: `/v1/sites/${siteId}/category`,
    method: 'POST',
    body: {
      name,
      parentId,
      status: 'draft',
    },
  });

  return {
    category: data.category,
  };
}
