import type { ListPostsParameters } from '@voidfull/js-sdk';

import type { Category } from './category';
import { f } from '../utils';

export interface Post {
  id: string;
  title: string;
  content: string;
  contentMarkdown: string;
  slug?: string;
  featureImage?: string;
  publishedAt?: string;
  createdAt: string;
  updatedAt?: string;
}

interface GetPostsResponse {
  posts: Post;
}

export async function getPosts({
  siteId,
}: ListPostsParameters): Promise<GetPostsResponse> {
  const data = await f({
    auth: true,
    url: `/v1/sites/${siteId}/posts`,
    method: 'GET',
  });

  return {
    posts: data.posts,
  };
}

export interface CreatePostArgs {
  siteId: string;
  title: string;
  contentMarkdown?: string;
  slug?: string;
  featureImage?: string;
}

interface CreatePostResponse {
  post: Post;
}

export async function createPost({
  siteId,
  title,
  contentMarkdown,
  slug,
}: CreatePostArgs): Promise<CreatePostResponse> {
  const data = await f({
    auth: true,
    url: `/v1/sites/${siteId}/posts`,
    method: 'POST',
    body: {
      title,
      contentMarkdown,
      slug,
    },
  });

  return {
    post: data.post,
  };
}

export interface PostCategory extends Category {
  pcId: string;
}
