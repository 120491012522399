import type {
  CustomDimension,
  TrackEventParams,
  TrackPageViewParams,
  TrackParams,
} from './types';

export function Matomo() {
  if (typeof window === 'undefined') return;
  if (!import.meta.env.VITE_MATOMO_TRACKER_ID) return;

  const u = 'https://metric-madness.voidfull.com/';

  window._paq = window._paq || [];
  pushInstruction('setTrackerUrl', `${u}matomo.php`);
  pushInstruction('enableLinkTracking');
  pushInstruction('setSiteId', import.meta.env.VITE_MATOMO_TRACKER_ID);

  const doc = document;
  const scriptElement = doc.createElement('script');
  const scripts = doc.getElementsByTagName('script')[0];

  scriptElement.type = 'text/javascript';
  scriptElement.async = true;
  scriptElement.defer = true;
  scriptElement.src = `${u}matomo.js`;

  if (scripts?.parentNode) {
    scripts.parentNode.insertBefore(scriptElement, scripts);
  }
}

export function trackEvent({
  category,
  action,
  name,
  value,
  ...otherParams
}: TrackEventParams) {
  if (category && action) {
    track({
      data: ['trackEvent', category, action, name, value],
      ...otherParams,
    });
  } else {
    throw new Error('Error: category and action are required.');
  }
}

export function trackPageView(params?: TrackPageViewParams): void {
  track({ data: ['trackPageView'], ...params });
}

function track({
  data = [],
  documentTitle = window.document.title,
  href,
  customDimensions = false,
}: TrackParams) {
  if (data.length) {
    if (
      customDimensions &&
      Array.isArray(customDimensions) &&
      customDimensions.length
    ) {
      customDimensions.map((customDimension: CustomDimension) =>
        pushInstruction(
          'setCustomDimension',
          customDimension.id,
          customDimension.value,
        ),
      );
    }

    pushInstruction('setCustomUrl', href ?? window.location.href);
    pushInstruction('setDocumentTitle', documentTitle);
    pushInstruction(...(data as [string, ...unknown[]]));
  }
}

function pushInstruction(name: string, ...args: unknown[]) {
  if (typeof window === 'undefined') return;
  if (!import.meta.env.VITE_MATOMO_TRACKER_ID) return;

  window._paq.push([name, ...args]);
}
